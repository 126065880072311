import React, { Component } from 'react';
import styled from "styled-components"
import { DrawerLinkItem, SocialLink, SocialIcon, IconButton } from './styled/navigation'

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height:100vh;
	background-color: rgba(0,0,0,0.75);
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease-out;
	&.opened{
		opacity: 1;
		pointer-events: all;
	}
`;

const Aside = styled.aside`
	display: flex;
  flex-direction:column;
	position: fixed;
	top: 0;
	right:-100%;
	width: 400px;
	max-width: 80%;
	height:100vh;
	background-color: ${props => props.theme.black};
	box-shadow: ${props => props.theme.shadow};
	transition: right 0.3s ease-out;
	z-index: 100;
	pointer-events: none;
	&.opened{
		right:0;
		pointer-events: all;
	}
`;

const Header = styled.header`
	display: flex;
	align-items:center;
	width:100%;
	padding:0.8rem;
	height:115px;
`;

const Main = styled.main`
	display:flex;
	flex-direction:column;
	flex:1;
	padding:0.8rem;
`;

const BaseNavWrapper = styled.div`
	display:flex;
	flex:1;
	flex-direction:row;
	border-bottom:solid 2px ${props => props.theme.red};
	margin: 0 2rem;
`;

const MobileNavWrapper = styled(BaseNavWrapper)`
	display:flex;	
	justify-content:flex-end;
`;

const SVGIcon = styled.svg`
	fill:black;
`;

const NavWrapper = styled.div`
	margin: 0 2rem;
	flex:1;
	display:flex;
	flex-direction:column;
`;

const Nav = styled.div`
	display:flex;
	flex-direction:column;
	flex:1;
	align-items:center;
	padding:0;
	margin:0;
	margin-bottom:5px;
`;

const Social = styled.div`
	display:flex;
	padding: 2rem 0;
	justify-content:center;
	margin-bottom:7rem;
`;



class SideDrawer extends Component {

	onCloseDraw(){
		this.props.toggleHandler();
	}

  updateDimensions() {
		if(window.innerWidth > 600 && this.props.opened){
			this.props.toggleHandler()
		}
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
	}
	
	render() {
		const opened = this.props.opened;
		return (
			<React.Fragment>
				<Backdrop className={opened ? 'opened' : ''} onClick={() => {
					this.props.toggleHandler()
				}} />
				<Aside className={opened ? 'opened' : ''}>
					<Header>
						<MobileNavWrapper>
							<IconButton onClick={() => this.props.toggleHandler()} >
								<SVGIcon viewBox="0 0 20 20">
									<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/>
								</SVGIcon>
							</IconButton>
						</MobileNavWrapper>
					</Header>
			
					<Main>
						
						<NavWrapper>

							<Nav>
								<DrawerLinkItem 
									to="/"
									activeClassName="active"
								>
									Home
								</DrawerLinkItem>
								<DrawerLinkItem 
									to="/about"
									activeClassName="active"
									partiallyActive={true}
								>
									About
								</DrawerLinkItem>
								<DrawerLinkItem 
									to="/projects"
									activeClassName="active"
									partiallyActive={true}
								>
									Projects
								</DrawerLinkItem>

								<DrawerLinkItem 
									to="/testimonials"
									activeClassName="active"
									partiallyActive={true}
								>
									Testimonials
								</DrawerLinkItem>

								<DrawerLinkItem 
									to="/services"
									activeClassName="active"
									partiallyActive={true}
								>
									Services
								</DrawerLinkItem>

								<DrawerLinkItem 
									to="/contact"
									activeClassName="active"
									partiallyActive={true}
								>
									Contact
								</DrawerLinkItem>

							</Nav>
							

							<Social>
								<SocialLink 
									href="https://www.facebook.com/Box-Architectural-Services-Ltd-113904493651098"
									target="_blank"
									title="BOX Architectural Services Ltd Facebook Account"
								>
									<SocialIcon social="facebook" />
								</SocialLink>
								<SocialLink 
									href="https://twitter.com/BoxasLtd" 
									target="_blank"
									title="BOX Architectural Services Ltd Twitter Account"
								>
									<SocialIcon social="twitter" />
								</SocialLink>
								<SocialLink 
									href="https://www.linkedin.com/company/planarch-design-ltd/" 
									target="_blank"
									title="BOX Architectural Services Ltd Twitter Account"
								>
									<SocialIcon social="linkedin" />
								</SocialLink>
							</Social>
							
						</NavWrapper>

					</Main>
				</Aside>
			</React.Fragment>
		);
	}
}

export default SideDrawer;