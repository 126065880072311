import React, { Component } from 'react';
import styled from "styled-components"
import { LinkItem, SocialLink, SocialIcon, IconButton } from './styled/navigation'
import Logo from "../components/logo"
import SideDrawer from './side-drawer'

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
	background: #000;
`
const Wrapper = styled.div`
	display: flex;
	align-items:center;
	width:100%;
	padding:0.8rem;
`;
const LogoWrapper = styled.div`
	width: 150px;
	height: 150px;
	
	@media only screen and (max-width: 800px) {
		width: 100px;
		height: 100px;
	}
`;

const NavWrapper = styled.div`
	display:flex;
	flex:1 0 0%;
	justify-content:center;
	flex-direction:row;
	border-bottom:solid 2px ${props => props.theme.red};
	
`;
const NavContainer = styled.div`
	display:flex;
	justify-content:center;
	flex-direction:column;
	flex:1 0 0%;
	margin: 0 2rem;
`;

const Nav = styled.div`
	display:flex;
	flex:1 0 0%;
	justify-content:center;
	flex-wrap:wrap;
	padding:0;
	margin:0;
	margin-bottom:5px;
	@media only screen and (max-width: 800px) {
		display:none;	
	}
`;

const Social = styled.div`
	display:flex;
	flex:1 0 0%;
	justify-content:flex-end;
	align-items:center;
	@media only screen and (max-width: 800px) {
		display:none;	
	}
`;



const MobileNavWrapper = styled.div`
	display:none;
	justify-content:flex-end;
	flex:1 0 0%;
	flex-direction:row;
	@media only screen and (max-width: 800px) {
		display:flex;	
	}
`;

const SVGIcon = styled.svg`
	fill:black;
`;

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sideDrawerOpened: false,
		};
	}

	toggleSideDrawer(){
		const flag = this.state.sideDrawerOpened;
		this.setState({ sideDrawerOpened: !flag })
	}
	render() {
		return (
			<React.Fragment>
				<HeaderContainer>
					<Wrapper>
						<LogoWrapper>
							<Logo />
						</LogoWrapper>

						<NavContainer>
							<NavWrapper>
							
								<Nav>
									<LinkItem 
										to="/"
										activeClassName="active"
									>
										Home
									</LinkItem>
									<LinkItem 
										to="/about"
										activeClassName="active"
										partiallyActive={true}
									>
										About
									</LinkItem>
									<LinkItem 
										to="/projects"
										activeClassName="active"
										partiallyActive={true}
									>
										Projects
									</LinkItem>

									<LinkItem 
										to="/testimonials"
										activeClassName="active"
										partiallyActive={true}
									>
										Testimonials
									</LinkItem>

									<LinkItem 
										to="/services"
										activeClassName="active"
										partiallyActive={true}
									>
										Services
									</LinkItem>

									<LinkItem 
										to="/contact"
										activeClassName="active"
										partiallyActive={true}
									>
										Contact
									</LinkItem>
								</Nav>

								<MobileNavWrapper>
									<IconButton onClick={this.toggleSideDrawer.bind(this)} >
										<SVGIcon viewBox="0 0 20 20">
											<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
										</SVGIcon>
									</IconButton>
								</MobileNavWrapper> 
							</NavWrapper>

							<Social>
								<SocialLink 
									href="https://www.facebook.com/Box-Architectural-Services-Ltd-113904493651098"
									target="_blank"
									title="BOX Architectural Services Ltd Facebook Account"
								>
									<SocialIcon social="facebook" />
								</SocialLink>
								<SocialLink 
									href="https://twitter.com/BoxasLtd" 
									target="_blank"
									title="BOX Architectural Services Ltd Twitter Account"
								>
									<SocialIcon social="twitter" />
								</SocialLink>
								<SocialLink 
									href="https://www.linkedin.com/company/planarch-design-ltd/" 
									target="_blank"
									title="BOX Architectural Services Ltd Twitter Account"
								>
									<SocialIcon social="linkedin" />
								</SocialLink>
							</Social>

						</NavContainer>
		
					</Wrapper>
				</HeaderContainer>
				<SideDrawer 
					opened={this.state.sideDrawerOpened}
					toggleHandler={this.toggleSideDrawer.bind(this)}
				/>
			</React.Fragment>
		);
	}
}


// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
